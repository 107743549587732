<template lang="pug">
  div
    .container-fluid#wrapper
      #title {{rockyName}}に本日チェックインしたユーザー<br><small>※非公開ユーザーを除く</small>
      #main-content
        #results(v-if="count>0") {{count}}ユーザーがチェックインしました。
        #results(v-if="count==0") チェックインしたユーザーはいません。
        #table 
          .table-row(@click="callRouterInfo(user)" v-for="(user,index) in checkinUserList" :value="user")
            .time {{user.time}}
            .badgeIcon
              img(:src="createImgPath(user.iconPath)" v-if="!user.iconPath==''")
            .name(@click="callRouterInfo(user)") {{user.name}}
</template>

<script>
import axios from 'axios'

export default {
  // データオブジェクト
  props:{
  },
  data() {
    return {
      checkinUserList: this.$route.params.checkinUserList,
      rockyName: this.$route.params.rockyName,
      rockyId: this.$route.params.rockyId,
      allRockyJsonData:[],
      count:0
    }
  },
  created(){
    this.createdProcess()
  },
  // メソッド
  methods: {
    callRouterInfo(item){
      this.$router.push('/myPage/' + item.id +'/userInfoTop')
    },
    async createdProcess(){

      //直接アクセスなど遷移元からユーザーListが取得できなかった場合はデータ取得する
      if(this.checkinUserList==undefined){
        //allRockyList.jsonから岩場名を取得する
        await this.getAllRockyJson()
        let rockyJsonData = this.allRockyJsonData.filter(record => record.rockyId == this.rockyId)
        this.rockyName = rockyJsonData[0].name
        //チェックイン中ユーザーを取得する
        await this.getCheckingInUserAndCount(this.rockyId)
      }
      this.count = this.checkinUserList.length
    },
    //-----------------------------------
    // 現在チェックイン中のユーザーを取得する
    //-----------------------------------
    async getCheckingInUserAndCount(rockyId){

      let jsonUrl = ''
      if(this.$user.isLogin){
        //ログイン時
        jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-checkin/checkin-count-and-userlist-logined'
      }else{
        //未ログイン時
        jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-checkin/checkin-count-and-userlist-not-logined'
      }

      await axios
        .get(jsonUrl,{
          params: {
            'rockyId': rockyId,
          },         
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          this.checkinUserList = response.data.checkingInUserList
        })
        .catch(function (error) {alert(error)})
    }, 
    //------------------------
    //全岩場JSON取得
    //------------------------
    async getAllRockyJson(){
      //岩場情報JSON
      let jsonUrl = process.env.VUE_APP_MATERIAL_URL + '/allRockyList.json'
      await axios
        .get(jsonUrl)
        .then(response => {
          this.allRockyJsonData = response.data
        })
        .catch(function (error) {alert(error)})

      //データが0件の場合
      if(this.allRockyJsonData.length == 0){
        //ここでデータが取得できない場合は、データ不正
        await this.asyncDialog('システムエラー：岩場情報を取得できませんでした')
      　return
      }
    },
    createImgPath(path){
      return this.getStatusIconPath(path)
    },
  },
  // コンポーネント
  components: {
  }
}
</script>
<style lang="stylus" scoped>
#wrapper
  padding-top 10px

  #title
    margin-top 2rem
    //text-align center
    font-size 1rem
    font-weight 600  
    color #0664A9

  #main-content
    #results
      margin 1rem 0 0.5rem 0
    #table
      margin-bottom 3rem
      .table-row
        height 50px
        display flex
        align-items center
        width 100%
        background #eceeef
        color #374047
        margin-bottom 2px
        cursor pointer
        .time
          width 15%
          text-align center
        .badgeIcon
          width 15%
          text-align center
          img
            width 40px
            height 40px
            border-radius 50%   
        .name
          width 70%
          font-weight 500
          text-decoration underline
          padding-left 0.5rem
      .table-row:hover
        background #2d8fd5
        .time,.name
          color #FFF
        .badgeIcon
          img
            filter brightness(120%)

@media (min-width 992px)
  #wrapper
    width 50%
    #main-content
      #table
        margin-bottom 4rem
        .table-row
          height 70px       
          .badgeIcon
            img
              width 50px
              height 50px 

</style>